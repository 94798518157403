<template>
  <div class="file-upload">
    <el-upload
      v-show="isShowFileUpload"
      class="upload"
      :class="{'upload-required' : isRequired && fileList.length === 0}"
      ref="fileUpload"
      list-type="picture-card"
      :action="fileRequest"
      :accept="fileType"
      :headers="fileHeaders"
      :data="fileData"
      :file-list="fileList"
      :on-change="fileChange"
      :before-remove="fileBeforeRemove"
      :on-remove="fileRemove"
      :before-upload="fileBeforeUpload"
      :on-success="fileSuccess"
      :on-error="fileError"
      :auto-upload="true"
      :show-file-list="false"
      :multiple="false"
    >
      <span
        slot="default"
        style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column;"
      >
        <!-- <img v-if="fileImg" :src="fileImg" class="avatar" /> -->
        <el-image v-if="fileImg" :src="fileImg" class="upload-img" fit="contain"></el-image>
        <i v-else class="el-icon-plus"></i>
        <!-- <span
          style="height: 16px; line-height: 16px; position: relative; bottom: -30px;"
        >{{$t('btnI18n.Upload')}}</span>-->
      </span>
    </el-upload>
    <div v-show="isShowUploadTips && showUploadTips.length" class="tips-text">{{ showUploadTips }}</div>
    <div slot="example" class="file-example" v-show="isShowExampleImg && isShowExample">
      <el-image class="example-img" :src="exampleImg[0]">
        <div slot="error" class="el-image__error">{{ $t("tipsMessage.LoadingFailed") }}</div>
      </el-image>
      <p class="example-txt">{{ $t("tipsMessage.UploadExampleTxt") }}</p>
    </div>
    <!-- <div
      slot="file"
      class="upload-preview"
      v-for="(item, index) in fileList"
      :key="index"
      v-show="isShowFileList"
    >
      <el-image
        v-if="item.raw.type.includes('image')"
        :src="item.url"
        class="upload-img"
        fit="contain"
      ></el-image>
      <i v-else class="el-icon-files file-icon"></i>
      <i class="el-icon-circle-close icon-file-remove" @click="fileBeforeRemove(item)"></i>
    </div>-->
    <!--  编辑时候，回显图片处理 start  -->
    <!-- <div
      slot="file"
      class="upload-preview"
      v-for="(item, index) in fileShowList"
      :key="index"
      v-show="isShowFileList"
    >
      <el-image
        v-if="/.*(\.png|\.jpg|\.jpeg|\.gif|\.PNG|\.JPG|\.JPEG|\.GIF)$/.test(item)"
        :src="item"
        class="upload-img"
        fit="contain"
      ></el-image>
      <i v-else class="el-icon-files file-icon"></i>
      <i class="el-icon-circle-close icon-file-remove" @click="fileBeforeRemoveShow(item)"></i>
    </div>-->
    <!--  编辑时候，回显图片处理 end  -->
    <!-- <el-dialog :title="$t('dialogMsg.title')" :visible.sync="dialogVisRemove">
      <div class="dialog-ct-box">
        <div class="dialog-ct">
          <p>{{ $t('sureRemove') }} {{ removeFile.name || "" }}</p>
        </div>
        <div class="dialog-btn">
          <el-button class="btn-gray btn-submit" @click="fileRemove">{{ $t("btnI18n.Confirms") }}</el-button>
          <el-button
            class="btn-black btn-submit"
            @click="dialogVisRemove = false"
          >{{ $t("btnI18n.Cancel") }}</el-button>
        </div>
      </div>
    </el-dialog>-->
    <!--  编辑时候，回显图片处理 start  -->
    <!-- <el-dialog :title="$t('dialogMsg.title')" :visible.sync="dialogVisRemoveShow">
      <div class="dialog-ct-box">
        <div class="dialog-ct">
          <p>{{ $t('sureRemove') }} {{ removeFileNameShow || "" }}</p>
        </div>
        <div class="dialog-btn">
          <el-button
            class="btn-gray btn-submit"
            @click="fileRemoveShow"
          >{{ $t("btnI18n.Confirms") }}</el-button>
          <el-button
            class="btn-black btn-submit"
            @click="dialogVisRemoveShow = false"
          >{{ $t("btnI18n.Cancel") }}</el-button>
        </div>
      </div>
    </el-dialog>-->
    <!--  编辑时候，回显图片处理 end  -->
  </div>
</template>

<script>
export default {
  name: "FileUpload",
  props: {
    isShowUploadTips: {
      type: Boolean,
      default: false
    },
    showUploadTips: {
      type: String,
      default: ""
    },
    isShowFileUpload: {
      type: Boolean,
      default: true
    },
    fileRequest: {
      type: String,
      default: "/api/v1/upload/file"
    },
    fileData: { // card ID_CARD_FRONT, ID_CARD_BACK, BUSINESS_LICENSE
      type: Object,
      default: () => { return {}; }
    },
    fileType: {
      type: String,
      default: ".jpg, .jpeg, .png, .pdf, .JPG, .JPEG, .PNG, .PDF"
    },
    fileSize: {
      type: [Number, String],
      default: 0
    },
    fileWidth: {
      type: [Number, String],
      default: 0
    },
    fileHeight: {
      type: [Number, String],
      default: 0
    },
    isShowFileList: {
      type: Boolean,
      default: true
    },
    isShowExampleImg: {
      type: Boolean,
      default: true
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    exampleImg: {
      type: Array,
      default: () => { return []; }
    },
    fileShowList: {
      type: Array,
      default: () => { return []; }
    }
  },
  data() {
    return {
      fileHeaders: {},
      fileList: [],
      fileImg: '',
      fileListBack: [],
      fileUrlBack: "",
      fileUploadFlag: true,
      dialogVisRemove: false,
      removeFile: {},
      // 回显图片处理
      dialogVisRemoveShow: false,
      removeFileNameShow: "",
    };
  },
  mounted() {
    // this.fileHeaders = { address: this.$store.getters['auth/user'].token, type: this.$store.getters['auth/user'].type };
    this.fileHeaders = { token: this.$store.getters['auth/token'] };
  },
  computed: {
    isShowExample() {
      return this.fileList.length === 0;
    }
  },
  methods: {
    // 回显图片处理 start
    fileBeforeRemoveShow(file) {
      this.removeFileNameShow = file.substr(file.lastIndexOf("/") + 1);
      this.dialogVisRemoveShow = true;
    },
    fileRemoveShow() {
      this.$emit("fileResponse", { data: { isRemove: true, url: "" } });
      this.dialogVisRemoveShow = false;
    },
    // 回显图片处理 end
    fileChange(files, fileList) {
      // console.log("fileChange", files, fileList);
      // if (fileList.length > 0 && this.fileUploadFlag) {
      //   this.fileList = [fileList[fileList.length - 1]];
      // }
    },
    fileBeforeRemove(files, fileList) {
      this.removeFile = files || {};
      this.dialogVisRemove = true;
    },
    fileRemove(files, fileList) {
      if (!fileList) {
        this.fileListBack = [];
        this.$emit("fileResponse", { data: { url: "" } });
      }
      this.fileList = this.fileListBack;
      this.dialogVisRemove = false;
    },
    fileBeforeUpload(file) {
      let _this = this;
      let isSize, isWH = true;
      this.fileUploadFlag = true;
      // const isType = this.fileType.split(",").includes(file.raw.type);
      // 大小，M为单位计算
      if (this.fileSize) {
        isSize = file.size / 1024 / 1024 < this.fileSize;
        if (!isSize) {
          this.fileUploadFlag = false;
          this.$emit("fileResponse", { data: null });
          this.$message.warning(this.$t("tipsMessage.UploadImgError"));
          return false;
        }
      }
      if (_this.fileWidth && _this.fileHeight) {
        // 尺寸
        isWH = new Promise((resolve, reject) => {
          let width = _this.fileWidth;
          let height = _this.fileHeight;
          let _URL = window.URL || window.webkitURL;
          let img = new Image();
          img.onload = () => {
            let valid = img.width <= width && img.height <= height;
            valid ? resolve() : reject();
          };
          img.src = _URL.createObjectURL(file);
        }).then(() => {
          return file;
        }, () => {
          this.fileUploadFlag = false;
          this.$emit("fileResponse", { data: null });
          this.$message.warning(this.$t("tipsMessage.UploadImgError"));
          return Promise.reject();
          return false;
        });
      }
      return isSize && isWH;
    },
    fileSuccess(response, files, fileList) {
      this.fileList = [fileList[fileList.length - 1]];
      this.fileImg = this.fileList[0].url;
      this.$emit("fileResponse", response);
    },
    fileError(files, fileList) {
      // console.log("fileError", files, fileList);
    },
  }
};
</script>

<style lang="scss">
.upload-required {
  .el-upload--picture-card,
  .el-upload--text {
    border-color: red !important;
  }
}
</style>
<style scoped lang="scss">
.file-upload {
  display: flex;
  display: -webkit-flex;
  .upload {
    width: 148px;
    height: 148px;
  }
  .file-example {
    margin-left: 20px;
    width: 148px;
    height: 148px;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    text-align: center;
    .example-img {
      width: 146px;
      height: 100px;
    }
    .example-txt {
      color: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 23px;
      line-height: 23px;
      padding: 0;
      margin: 0;
    }
  }
  .upload-preview {
    margin-left: 20px;
    width: 148px;
    height: 148px;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    .upload-img {
      width: 136px;
      height: 136px;
    }
    .icon-file-remove {
      position: absolute;
      right: 0;
      top: 0;
      color: red;
      font-size: 20px;
      font-weight: 800;
      cursor: pointer;
    }
    .file-icon {
      font-size: 60px;
    }
  }
  .dialog-ct-box {
    text-align: center;
  }
  .dialog-btn {
    text-align: right;
  }
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.tips-text {
  text-align: center;
  color: #a8eade;
  font-size: 1rem;
  font-weight: 600;
}
</style>
