<template>
  <div>
    <div class="title">{{$t('baseInfo')}}</div>
    <el-form :rules="rules" ref="form" :model="form" label-width="270px">
      <el-form-item
        :label="$t('companyCreate.certificateOfIncorporation')"
        required
        style="width: 100%;"
      >
        <file-upload
          :fileSize="4"
          :fileRequest="'/api/v1/upload/file'"
          :fileType="'.jpg, .jpeg, .png, .JPG, .JPEG, .PNG'"
          @fileResponse="fileResponseCOI"
          :isRequired="requiredCooperation"
          :isShowExampleImg="false"
        ></file-upload>
        <div class="upload-tips">{{ $t('formPlaceHolder.supportUploadFileLicense') }}</div>
      </el-form-item>
      <el-form-item :label="$t('companyCreate.businessRegistration')" required style="width: 100%;">
        <file-upload
          :fileSize="4"
          :fileRequest="'/api/v1/upload/file'"
          :fileType="'.jpg, .jpeg, .png, .JPG, .JPEG, .PNG'"
          @fileResponse="fileResponseRegistration"
          :isRequired="requiredRegistration"
          :isShowExampleImg="false"
        ></file-upload>
        <div class="upload-tips">{{ $t('formPlaceHolder.supportUploadFileLicense') }}</div>
      </el-form-item>
      <el-form-item
        :label="$t('companyCreate.legalHoldingStructure')"
        required
        style="width: 100%;"
      >
        <file-upload
          :fileSize="4"
          :fileRequest="'/api/v1/upload/file'"
          :fileType="'.jpg, .jpeg, .png, .JPG, .JPEG, .PNG'"
          @fileResponse="fileResponseStructure"
          :isRequired="requiredCertificate"
          :isShowExampleImg="false"
        ></file-upload>
        <div class="upload-tips">{{ $t('formPlaceHolder.supportUploadFileLicense') }}</div>
      </el-form-item>
      <el-form-item :label="$t('brandOwnerNameColumn')" prop="name">
        <el-input
          v-model="form.name"
          :placeholder="$t('formPlaceHolder.phBrandOwnerName')"
          maxlength="60"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('registeredAddress')" prop="registeredAddress">
        <el-input
          v-model="form.registeredAddress"
          :placeholder="$t('formPlaceHolder.phRegisteredAddress')"
          maxlength="100"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('theRegisteredCapital')" prop="registeredCapital">
        <el-input
          v-model="form.registeredCapital"
          :placeholder="$t('formPlaceHolder.phRegisteredCapital')"
          style="width: calc(100% - 130px);"
          maxlength="50"
          @input="changeCapital"
        ></el-input>
        <span>&nbsp;&nbsp;{{$t('millionDollars')}}</span>
      </el-form-item>
      <el-form-item :label="$t('socialCreditCode')" prop="registeredNumber">
        <el-input
          v-model="form.registeredNumber"
          :placeholder="$t('formPlaceHolder.phUnifiedSocialCreditCode')"
          maxlength="18"
          minlength="18"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('businessScope')" style="width:1100px" prop="businessScope">
        <el-input
          type="textarea"
          v-model="form.businessScope"
          :placeholder="$t('formPlaceHolder.phScopeOfBusiness')"
          :autosize="{ minRows: 4}"
          maxlength="1000"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('businessAddress')" prop="officeAddress">
        <el-input
          v-model="form.officeAddress"
          :placeholder="$t('formPlaceHolder.phOfficeAddress')"
          maxlength="100"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('businessMail')" prop="corporateEmail">
        <el-input
          v-model="form.corporateEmail"
          :placeholder="$t('formPlaceHolder.phCorporateEmail')"
          maxlength="50"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('companyCreate.directorsDocumentation')"
        required
        style="width: 100%;"
      >
        <div class="form-upload-ph">{{ $t('tipsMessage.UploadTipsIdCard') }}</div>
        <file-upload
          :fileSize="4"
          :fileRequest="'/api/v1/upload/file'"
          :fileType="'.jpg, .jpeg, .png, .JPG, .JPEG, .PNG'"
          @fileResponse="fileResponseDocFront"
          :isRequired="requiredDocFront"
          :isShowExampleImg="false"
          :isShowUploadTips="true"
          showUploadTips="Front Side"
          class="upload-front"
        ></file-upload>
        <file-upload
          :fileSize="4"
          :fileRequest="'/api/v1/upload/file'"
          :fileType="'.jpg, .jpeg, .png, .JPG, .JPEG, .PNG'"
          @fileResponse="fileResponseDocBack"
          :isRequired="requiredDocBack"
          :isShowExampleImg="false"
          :isShowUploadTips="true"
          showUploadTips="Back Side"
          class="upload-back"
        ></file-upload>
        <div class="upload-tips">{{ $t('formPlaceHolder.supportUploadFileLicense') }}</div>
      </el-form-item>
      <el-form-item :label="$t('companyCreate.directorName')" prop="directorName">
        <el-input
          v-model="form.directorName"
          :placeholder="$t('companyCreate.phDirectorName')"
          maxlength="50"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('companyCreate.directorIdNumber')" prop="directorCertNumber">
        <el-input
          v-model="form.directorCertNumber"
          :placeholder="$t('companyCreate.phDirectorIdNumber')"
          maxlength="18"
          minlength="18"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('companyCreate.contactPhoneNumber')" prop="directorMobileNumber">
        <el-input
          v-model="form.directorMobileNumber"
          :placeholder="$t('companyCreate.phContactPhoneNumber')"
          maxlength="20"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="title">{{$t('bankInformation')}}</div>
    <el-form
      ref="formBank"
      :model="formBank"
      :rules="rules"
      style="width: 1100px"
      label-width="270px"
    >
        <div>
            <el-form-item :label="$t('bankOfCityCountry')" prop="areaId">
                <el-select v-model="bankCountryCity" :placeholder="$t('formPlaceHolder.phSelect')" style="width: 100%"
                           @change="changeCountry"
                >
                    <template v-if="language==='zh-cn'">
                        <el-option
                                v-for="item in optionsCountry"
                                :key="item.code"
                                :label="item.cnName"
                                :value="item.cnName">
                        </el-option>
                    </template>
                    <template v-else>
                        <el-option
                                v-for="item in optionsCountry"
                                :key="item.code"
                                :label="item.enName"
                                :value="item.enName">
                        </el-option>
                    </template>
                </el-select>
            </el-form-item>
        </div>
        <el-form-item :label="$t('bankOfDeposit')" prop="receivingBank">
            <el-select v-if="optionsBank.length" v-model="bankName" :placeholder="$t('formPlaceHolder.phReceivingBankSel')" style="width: 100%"
                       @change="changeBank" ref="select"
            >
                <template v-if="language==='zh-cn'">
                    <el-option
                            v-for="item in optionsBank"
                            :key="item.swiftCode"
                            :label="item.cnName"
                            :value="item.cnName">
                        <div class="flex-row">
                            <el-image :src="item.icon" style="width: 18px; height: 18px; margin-right: 10px;"></el-image>
                            {{item.cnName}}
                        </div>
                    </el-option>
                </template>
                <template v-else>
                    <el-option
                            v-for="item in optionsBank"
                            :key="item.swiftCode"
                            :label="item.enName"
                            :value="item.enName">
                        <div class="flex-row">
                            <el-image :src="item.icon" style="width: 18px; height: 18px; margin-right: 10px;"></el-image>
                            {{item.enName}}
                        </div>
                    </el-option>
                </template>
            </el-select>
            <el-input v-else
                      v-model="formBank.receivingBank"
                      :placeholder="$t('formPlaceHolder.phReceivingBank')"
            ></el-input>
        </el-form-item>
        <el-form-item :label="$t('companyCreate.swiftCode')" prop="swiftCode">
            <el-input
                    v-model="formBank.swiftCode"
                    :placeholder="$t('companyCreate.phSwiftCode')"
                    minlength="8"
                    maxlength="11"
            ></el-input>
        </el-form-item>
      <el-form-item :label="$t('companyCreate.bankNumber')" prop="bankAccountNumber">
        <el-input
          v-model="formBank.bankAccountNumber"
          :placeholder="$t('companyCreate.phBankAccountNumber')"
          maxlength="30"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('companyCreate.bankAccount')" prop="accountHolderName">
        <el-input
          v-model="formBank.accountHolderName"
          :placeholder="$t('companyCreate.phAccountHolderName')"
          maxlength="50"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="title">{{$t('purchaseTitle')}}</div>
    <el-form ref="form3" :model="form3" :rules="rules" style="width: 1100px" label-width="270px">
      <el-form-item :label="$t('purchaseAmountYear')" prop="annualPurchaseAmount">
        <el-input
          v-model="form3.annualPurchaseAmount"
          :placeholder="$t('formPlaceHolder.phAnnualPurchaseAmount')"
          style="width: calc(100% - 130px);"
        ></el-input>
        <span>&nbsp;&nbsp;{{$t('millionDollars')}}</span>
      </el-form-item>
      <el-form-item :label="$t('numberOfFrameSuppliers')" prop="suppliereNumber">
        <el-input
          v-model="form3.suppliereNumber"
          :placeholder="$t('formPlaceHolder.phNumberOfFrameworkSuppliers')"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="flex-row-center">
      <el-button class="btn-black" @click="submitClick()">{{$t('submit')}}</el-button>
      <el-button class="btn-gray" @click="toLogin()">{{$t('btnI18n.Cancel')}}</el-button>
    </div>
  </div>
</template>

<script>
import FileUpload from "@/views/components/FileUpload";
import {getObjByKeyValue} from "@/utils";
export default {
  name: "enterprise-create",
  components: {
    FileUpload,
  },
  data() {
    let regEmail = (rule, value, callback) => {
      const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/ig;
      if (value === '' || value === null) {
        callback(new Error(this.$t('completeInput')));
      } else if (reg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t('formValidate.emailError')));
      }
    };
    let reg2Decimal = (rule, value, callback) => {
      const reg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error(''));
      }
    };
    let regPositiveNumber = (rule, value, callback) => {
      const reg = /^[1-9]\d*$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error(''));
      }
    };
    let regSwitchCode = (rule, value, callback) => {
      const reg = /^[0-9a-zA-Z]+$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error(""));
      }
    };
    return {
      imgUrl: this.$enums.IMG_URL,
      requiredDocFront: false,
      requiredDocBack: false,
      requiredCooperation: false,
      requiredRegistration: false,
      requiredCertificate: false,
      form: {
        coi: "",
        businessRegistration: "",
        legalHoldingStrcture: "",
        name: "",
        registeredAddress: "",
        registeredCapital: "",
        registeredNumber: "",
        businessScope: "",
        officeAddress: "",
        corporateEmail: "",
        directorCertFront: "",
        directorCertBack: "",
        directorName: "",
        directorCertNumber: "",
        directorMobileNumber: "",
      },
        // bank update
        optionsCountry: [],
        optionsBank: [],
        bankCountryCity: "",
        bankName: "",
        // bank update
      formBank: {
          areaId: "",
          areaBankId: "",
          receivingBank: "",
          receivingBankEn: "",
          swiftCode: "",
          receivingBankIcon: "",
          bankAccountNumber: "",
          accountHolderName: "",
      },
      form3: {
        annualPurchaseAmount: "",
        suppliereNumber: "",
      },
      rules: {
        name: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        officeAddress: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        registeredCapital: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        registeredAddress: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        directorName: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        directorCertNumber: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        directorMobileNumber: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        corporateEmail: [{ validator: regEmail, required: true, trigger: 'blur' }],
        registeredNumber: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        businessScope: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
          areaId: [{ required: true, message: this.$t('companyCreate.accountCannotEmpty'), trigger: 'blur' }],
          receivingBank: [
              { required: true, message: this.$t('companyCreate.bankCannotEmpty'), trigger: 'blur' },
              { required: true, message: this.$t('companyCreate.bankCannotEmpty'), trigger: 'change' }
          ],
        bankAccountNumber: [{ required: true, message: this.$t('companyCreate.accountCannotEmpty'), trigger: 'blur' }],
        accountHolderName: [{ required: true, message: this.$t('companyCreate.userCannotEmpty'), trigger: 'blur' }],
        swiftCode: [{ validator: regSwitchCode, required: true, message: this.$t('swiftCodeCannotEmpty'), trigger: 'blur' },
            { required: true, message: this.$t('swiftCodeCannotEmpty'), trigger: 'change' }],
        annualPurchaseAmount: [{ validator: reg2Decimal, required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        suppliereNumber: [{ validator: regPositiveNumber, required: true, message: this.$t('completeInput'), trigger: 'blur' }],
      },
      fileList: [],
      fileData: {},
      fileHeaders: {}
    };
  },
  mounted() {
    this.fileHeaders = { address: this.$store.getters['auth/user'].authorization, type: this.$store.getters['auth/user'].type };
      this.getAreaList();
  },
  methods: {
      // bank update
      getAreaList() {
          let _this = this;
          this.optionsCountry = [];
          this.$axios.get("/v1/bank/area").then(res => {
              if (!res.code) {
                  _this.optionsCountry = res.data;
              }
          });
      },
      getBankList(code) {
          let _this = this;
          this.optionsBank = [];
          this.$axios.get("/v1/bank/list", {params: { areaCode: code.toString() || "86" }}).then(res => {
              if (!res.code) {
                  _this.optionsBank = res.data;
              }
          });
      },
      // bank update
    changeCapital(val) {
      let valIndex = val.indexOf('.');
      if (valIndex !== -1) {
        val = this.form.registeredCapital = val.substring(0, valIndex + 3);
      }
    },
    fileResponseCOI(response) {
      if (response.data) {
        this.form.coi = response.data;
      }
    },
    fileResponseRegistration(response) {
      if (response.data) {
        this.form.businessRegistration = response.data;
      }
    },
    fileResponseStructure(response) {
      if (response.data) {
        this.form.legalHoldingStrcture = response.data;
      }
    },
    fileResponseDocFront(response) {
      if (response.data) {
        this.form.directorCertFront = response.data;
      }
    },
    fileResponseDocBack(response) {
      if (response.data) {
        this.form.directorCertBack = response.data;
      }
    },
      // bank update
      changeCountry(e) {
          this.bankName = "";
          this.formBank.receivingBank = "";
          this.formBank.receivingBankEn = "";
          this.formBank.swiftCode = "";
          this.formBank.receivingBankIcon = "";
          const key = this.language === "zh-cn" ? "cnName" : "enName";
          const {code} = getObjByKeyValue(this.optionsCountry, key, e);
          this.formBank.areaId = code.toString();
          this.getBankList(code);
      },
      changeBank(e) {
          const key = this.language === "zh-cn" ? "cnName" : "enName";
          const {swiftCode, icon, cnName, enName, id} = getObjByKeyValue(this.optionsBank, key, e);
          this.formBank.areaBankId = id;
          this.formBank.receivingBank = cnName;
          this.formBank.receivingBankEn = enName;
          this.formBank.swiftCode = swiftCode;
          this.formBank.receivingBankIcon = icon;
          this.$refs.select.$el.children[0].children[0].setAttribute('style',"background:url("+ icon +") no-repeat 10px;background-size: 20px 20px;color:#333;padding-left: 45px;");
      },
      // bank update
    submitClick() {
      let submit = true;
      let _this = this;
      if (!this.form.coi || !this.form.businessRegistration || !this.form.legalHoldingStrcture || !this.form.directorCertFront || !this.form.directorCertBack) {
        submit = false;
        this.requiredCooperation = !this.form.coi;
        this.requiredRegistration = !this.form.businessRegistration;
        this.requiredCertificate = !this.form.legalHoldingStrcture;
        this.requiredDocFront = !this.form.directorCertFront;
        this.requiredDocBack = !this.form.directorCertBack;
        return this.$message.error(this.$t('plaseUpload'));
      }
      submit && this.$refs.form.validate((valid) => {
        if (!valid) {
          submit = false;
          return this.$message.error(this.$t('submittedFail'));
        }
      });
      submit && this.$refs.formBank.validate((valid) => {
        if (!valid) {
          submit = false;
          return this.$message.error(this.$t('submittedFail'));
        }
      });
      submit && this.$refs.form3.validate((valid) => {
        if (!valid) {
          submit = false;
          return this.$message.error(this.$t('submittedFail'));
        }
      });
      if (submit) {
        let info = Object.assign({}, this.form, this.formBank, this.form3);
        info.registeredCapital = (Number(this.form.registeredCapital) * this.$enums.UNIT_MILLION).toString();
        info.annualPurchaseAmount = (Number(this.form3.annualPurchaseAmount) * this.$enums.UNIT_MILLION).toString();
        this.$axios.post('/v1/brand-owner/detail', info).then(result => {
          if (!result.code) {
            _this.$message({ type: 'success', message: this.$t('submittedSuccessfully') });
            _this.$router.push({ path: '/review' });
          } else {
            _this.$message.error(result.message);
          }
        });
      }
    },
    toLogin() {
      this.$router.push({ path: "/" });
    }
  },
  computed: {
    language() {
      return this.$store.getters['auth/language'];
    }
  },
  watch: {
    language() {
      console.log(this.language);
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  width: 550px;
  display: inline-block;
}
.upload-front,
.upload-back {
  display: inline-block;
}
.upload-back {
  margin-left: 20px;
}
</style>
